import {
  AfterViewChecked,
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AnnotationService,
  BookmarkViewService,
  FormDesignerService,
  FormFieldsService,
  LinkAnnotationService,
  MagnificationService,
  NavigationService,
  PageOrganizerService,
  PdfViewerComponent as pdfc,
  PrintService,
  TextFieldSettings,
  TextSearchService,
  TextSelectionService,
  ThumbnailViewService,
  ToolbarItem,
  ToolbarService,
  ToolbarSettingsModel,
} from '@syncfusion/ej2-angular-pdfviewer';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { NotificationService } from '@tremaze/shared/notification';
import { isNotEmpty } from '@tremaze/shared/util-utilities';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { Placeholder } from '@tremaze/placeholder';

@Component({
  selector: 'tremaze-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrl: './pdf-viewer.component.scss',
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    AnnotationService,
    FormDesignerService,
    FormFieldsService,
    PageOrganizerService,
  ],
})
export class PdfViewerComponent implements OnInit, AfterViewChecked {
  @Input({ required: true }) documentUrl?: string;
  @Input() documentData?: string;
  @Input({ required: true }) documentName = 'document.pdf';
  @Input() placeholders: Placeholder[] = [];
  @Input({ transform: booleanAttribute }) canWrite = true;
  @Input({ transform: booleanAttribute }) canReplacePlaceholders = true;

  private readonly _confirmation = inject(ConfirmationService);
  private readonly _notification = inject(NotificationService);
  private readonly _config = inject(AppConfigService);

  get serviceUrl() {
    if (this._config.state === 'PROD') {
      return 'https://pdfviewer28.tagea.app/api/pdfviewer';
    }
    return 'https://pdfviewer.dev.cloud.tagea.app/api/pdfviewer';
  }

  private readonly openFillableItem = {
    prefixIcon: 'e-form-field',
    tooltipText: 'Platzhalter',
    text: 'Platzhalter',
    id: 'openFillableItem',
  };

  get toolbarSettings(): ToolbarSettingsModel {
    const defaultItems: ToolbarItem[] = [
      'OpenOption',
      'PageNavigationTool',
      'MagnificationTool',
      'PanTool',
      'SelectionTool',
      'SearchOption',
      'PrintOption',
      'DownloadOption',
      'UndoRedoTool',
      'AnnotationEditTool',
      'FormDesignerEditTool',
      'CommentTool',
    ];

    return {
      toolbarItems: [
        ...(this.placeholders?.length ? [this.openFillableItem] : []),
        ...defaultItems,
      ],
      showTooltip: true,
    };
  }

  get proxyFixedDocumentUrl() {
    if (!this.documentUrl) {
      return;
    }
    return this.documentUrl.replace(
      'http://localhost:4200/api',
      'https://api.dev.cloud.tagea.app',
    );
  }

  private _initialized = false;

  @ViewChild(pdfc, { static: true }) pdfViewer?: pdfc;

  readonly resource = `${this.document.location.protocol}//${this.document.location.host}/assets/ej2-pdfviewer-lib`;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    if (this.documentData) {
      this.pdfViewer!.load(this.documentData, null as any);
    } else if (this.documentUrl) {
      this.pdfViewer!.load(this.proxyFixedDocumentUrl!, '');
    }
  }

  ngAfterViewChecked() {
    if (!this._initialized && this.pdfViewer) {
      this._initialized = true;
      if (this.canReplacePlaceholders && this.placeholders.length) {
        (this.pdfViewer.documentLoad as EventEmitter<any>).subscribe(() => {
          const field = this._findFirstFillableFieldWithEmptyValue();
          if (field) {
            this._confirmation
              .askUserForConfirmation({
                title: 'Automatisches Ausfüllen',
                text: 'Wir haben automatisch ausfüllbare Felder in diesem Dokument gefunden. Möchten Sie diese automatisch ausfüllen lassen?',
                confirmButtonText: 'Ja',
                denyButtonText: 'Nein',
              })
              .subscribe((r) => {
                if (r.confirmed) {
                  this.fillFields();
                }
              });
          }
        });
      }

      if (!this.canWrite) {
        this.pdfViewer.enableToolbar = false;
        this.pdfViewer.enableAnnotation = false;
        if (!this.canReplacePlaceholders) {
          this._setFormFieldsDisabled(true);
        } else {
          this._setFormFieldsDisabled(false);
        }
      } else {
        this._setFormFieldsDisabled(false);
      }
    }
  }

  private _setFormFieldsDisabled(disable: boolean) {
    if (this.pdfViewer) {
      this.pdfViewer.formFields.forEach((f) => {
        this.pdfViewer?.formDesignerModule?.updateFormField(f, {
          ...f,
          isReadOnly: disable,
        } as any);
      });

      this.pdfViewer.enableFormDesigner = !disable;
    }
  }

  private _showFillableItems = false;

  get showFillableItems() {
    return this._showFillableItems;
  }

  onToolbarClick(ev: { item: { id: string } }) {
    if (ev?.item?.id === 'openFillableItem') {
      this._showFillableItems = !this._showFillableItems;
    }
  }

  private _findFirstFillableFieldWithEmptyValue() {
    if (this.placeholders.length) {
      return this.pdfViewer?.formFields.find((f) => {
        const name = f.name;
        const fillableVariable = this.placeholders.find((v) => v.name === name);
        if (!fillableVariable || !isNotEmpty(fillableVariable.value)) {
          return false;
        }
        if (f.value === null || f.value === undefined || f.value === '') {
          return true;
        }
        return false;
      });
    }
    return undefined;
  }

  fillFields() {
    this._showFillableItems = false;
    let filledOutCount = 0;
    this.placeholders.forEach((v) => {
      const fields =
        this.pdfViewer?.formFields.filter((f) => f.name === v.name) ?? [];
      for (const field of fields) {
        const fieldValue = field?.value;
        const value = v?.value ?? '';
        if (
          field &&
          fieldValue !== value &&
          (fieldValue === null || fieldValue === undefined || fieldValue === '')
        ) {
          this.pdfViewer?.formDesignerModule.updateFormField(field.id!, {
            value: value,
          } as TextFieldSettings);
          filledOutCount++;
        }
      }
    });

    this._notification.showNotification(
      `Es wurden ${filledOutCount} Felder automatisch ausgefüllt.`,
    );
  }

  async save() {
    if (!this.pdfViewer) {
      return;
    }
    const blob = await this.pdfViewer.saveAsBlob();
    return new Blob([blob], { type: 'application/pdf' });
  }

  print() {
    if (!this.pdfViewer) {
      return;
    }
    this.pdfViewer.print.print();
  }
}
